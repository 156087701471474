import React from "react";
import { graphql } from "gatsby";

import { HeroBlock, Layout, PostFeed } from "~components";

/**
 * @typedef {{
 *  data: GatsbyTypes.ListingQuery
 * }} Props
 *
 * @extends {React.Component<Props>}
 */
export default class Listing extends React.Component {
  render() {
    const { data } = this.props;
    const { frontmatter } = data.page;

    return (
      <Layout pageData={data}>
        {frontmatter.content ? (
          <HeroBlock section={{ ...frontmatter, section_id: "listing-hero" }} />
        ) : null}
        <PostFeed options={frontmatter} />
      </Layout>
    );
  }
}

export const query = graphql`
  query Listing($url: String, $headerImg: String) {
    ...PageData
    page: markdownRemark(fields: { url: { eq: $url } }) {
      frontmatter {
        subfolder
        inverse
        content: description
        title
      }
    }
  }
`;
